<template>
  <div class="QuestionSurvey" :class="{'QuestionSurvey--Error':error}">
    <div v-if="isCheckpoint" class="QuestionSurvey--Data Checkpoint--Data">
      <div class="QuestionSurvey--IndexWrapper">
        <p class="QuestionSurvey--Index">{{checkpointOrder}}</p>
      </div>
      <div class="QuestionSurvey--Actions">
        <span class="icon-duplicate-icon" v-on:click="$emit('duplicate-checkpoint')"></span>
        <span class="icon-delete-icon" v-on:click="$emit('delete-checkpoint')"></span>
        <span class="icon-move-icon"></span>
      </div>
      <div class="Checkpoint">
        <p class="Checkpoint--Title">{{ checkpoint.name }}</p>
        <p class="Checkpoint--Message">{{ checkpoint.message }}</p>
        <p class="Checkpoint--Type">
          <svg v-if="checkpoint.type == 'URL_LOAD'" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3059_1239)">
            <path d="M12.6667 3.5V6.5" stroke="#8134FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.6667 12.5H18.6667" stroke="#8134FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.0304 18.8638L16.9089 16.7422" stroke="#8134FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6667 21.5V18.5" stroke="#8134FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.30295 18.8638L8.42451 16.7422" stroke="#8134FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.66669 12.5H6.66669" stroke="#8134FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.30295 6.13623L8.42451 8.25779" stroke="#8134FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_3059_1239">
            <rect x="0.666687" y="0.5" width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
          </svg>

          {{ $filters.formatCheckpointType(checkpoint.type) }}
        </p>
        <p class="Checkpoint--Url" v-if="checkpoint.type == 'URL_LOAD'">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3059_1251)">
            <path d="M9 15.5L15 9.5" stroke="#8134FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.5 7.63547L13.3181 4.82297C14.1644 3.99041 15.3053 3.52597 16.4924 3.5308C17.6795 3.53563 18.8166 4.00936 19.6561 4.84878C20.4955 5.68821 20.9692 6.82532 20.974 8.01244C20.9789 9.19955 20.5144 10.3405 19.6819 11.1867L16.8638 14.0002" stroke="#8134FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.13531 11L4.32281 13.8181C3.49024 14.6644 3.0258 15.8053 3.03063 16.9924C3.03547 18.1795 3.50919 19.3166 4.34861 20.1561C5.18804 20.9955 6.32515 21.4692 7.51227 21.474C8.69939 21.4789 9.84032 21.0144 10.6866 20.1819L13.5 17.3638" stroke="#8134FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_3059_1251">
            <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
          </svg>

          {{ checkpoint.checkUrl }}</p>
        <p class="Checkpoint--Element" v-if="checkpoint.type != 'URL_LOAD'">{{ checkpoint.elementSelector }}</p>
      </div>
    </div>
    <div class="QuestionSurvey--Data" v-if="!isCheckpoint">
      <div class="QuestionSurvey--IndexWrapper">
        <p class="QuestionSurvey--Index">{{question.order}}</p>
        <QuestionIMG class="QuestionSurvey--Index QuestionSurvey--Logo" :matrixRadioButton="isMatrixRadioButton()" :name="question.type.includes('RANKING') ? question.type + '-' + question.rankingType : question.type"/>
      </div>
      <div class="QuestionSurvey--Actions">
        <span class="icon-duplicate-icon" v-on:click="$emit('duplicate-question')"></span>
        <span class="icon-delete-icon" v-on:click="$emit('delete-question')"></span>
        <span class="icon-move-icon"></span>
      </div>
      <div class="QuestionSurvey--Title">
        <div class="TitleWithSwitcher">
          <label>{{$t('activity_survey_question_title')}}</label>
          <div class="StylesSwitcher" :class="{'switched':switchState}">
            <p v-on:click="switchDuration()" :class="{'active':!switchState}">{{$t('activity_survey_switcher_option_1')}}</p>
            <p v-on:click="switchDuration()" :class="{'active':switchState}">{{$t('activity_survey_switcher_option_2')}}</p>
          </div>
        </div>
        <SuiteInput ref="Title" :withStyles="titleWithStyles" required class="Input--SurveyCreation" placeholder='Ej. "¿Dónde harías click si tienes que ir a Contacto?"' v-model="question.text" v-on:data-update="dataIsUpdated()"/>
        <div v-if="hasHTMLcontent()" class="StylesInfo">
          <i class="fa fa-info-circle"></i>
          <span>{{$t('activity_survey_styles_info')}}<span class="Clear" v-on:click="clearHTMLtags()">{{$t('activity_survey_styles_info_click')}}</span></span>
        </div>
      </div>
      <div class="QuestionSurvey--Desc" v-if="showDescription">
        <label>{{$t('activity_survey_question_desc_focus')}}</label>
        <textarea :id="'auto-resize-'+question.id" v-model="question.description" :placeholder="$t('activity_survey_question_desc_focus_placeholder')" v-on:change="dataIsUpdated()"></textarea>
      </div>
      <div class="QuestionSurvey--BasicGroup" v-if="isMultiTextQuestion()">
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_placeholders')}}</label>
          <SuiteInput class="Input--SurveyCreation" placeholder='Ej. "Campo 1 // Campo 2"' v-model="question.placeholders" v-on:data-update="dataIsUpdated()"/>
        </div>
      </div>
      <div class="QuestionSurvey--BasicGroup" v-if="isMultiTextQuestion()">
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_description_texts')}}</label>
          <SuiteInput class="Input--SurveyCreation" placeholder='Ej. "Campo 1 // Campo 2"' v-model="question.textDescriptions" v-on:data-update="dataIsUpdated()"/>
        </div>
      </div>
      <div class="QuestionSurvey--BasicGroup" v-if="isNumberQuestion()">
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_magnitude')}}</label>
          <SuiteInput class="Input--SurveyCreation" placeholder='Ej. "km/h"' v-model="question.units" v-on:data-update="dataIsUpdated()"/>
        </div>
      </div>
      <div class="QuestionSurvey--BasicGroup" v-if="isNumberQuestion()">
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_digits')}}</label>
          <SuiteInput ref="Digits" :minLength="1" :validation="/^\d+$/" class="Input--SurveyCreation" placeholder='Ej. "3"' v-model="question.digitsNum" v-on:data-update="dataIsUpdated()"/>
        </div>
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_decimals')}}</label>
          <SuiteInput ref="Decimals" :minLength="1" :validation="/^\d+$/" class="Input--SurveyCreation" placeholder='Ej. "2"' v-model="question.decimalNum" v-on:data-update="dataIsUpdated()"/>
        </div>
      </div>
      <div class="QuestionSurvey--BasicGroup" v-if="isNumberQuestion()">
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_min_valoration')}}</label>
          <SuiteInput :minLength="1" :validation="/^\d+$/" class="Input--SurveyCreation" placeholder='Ej. "3"' v-model="question.numMin" v-on:data-update="dataIsUpdated()"/>
        </div>
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_max_valoration')}}</label>
          <SuiteInput :minLength="1" :validation="/^\d+$/" class="Input--SurveyCreation" placeholder='Ej. "2"' v-model="question.numMax" v-on:data-update="dataIsUpdated()"/>
        </div>
      </div>
      <div class="QuestionSurvey--BasicGroup" v-if="isMultiTextQuestion()">
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_texts_number') +': '+ question.textNum}}</label>
          <RangeSlider class="RangeSlider--Gradient" v-model="question.textNum" :min="1" :max="10" showActions v-on:update-finished="dataIsUpdated()"/>
        </div>
      </div>
      <div class="QuestionSurvey--BasicGroup" v-if="isRankingQuestion() && !isPollActivity">
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_question_label_left')}}</label>
          <SuiteInput class="Input--SurveyCreation" placeholder='Ej. "Muy mala"' v-model="question.rankingLeftLabel" v-on:data-update="dataIsUpdated()"/>
        </div>
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_question_label_rigth')}}</label>
          <SuiteInput class="Input--SurveyCreation" placeholder='Ej. "Perfecta"' v-model="question.rankingRightLabel" v-on:data-update="dataIsUpdated()"/>
        </div>
      </div>
      <div class="QuestionSurvey--BasicGroup" v-if="isRankingEnabled()">
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_min_valoration') +': '+ question.rankingMin}}</label>
          <RangeSlider class="RangeSlider--Gradient" v-model="question.rankingMin" :min="minRanking" :max="maxRanking" showActions v-on:update-finished="dataIsUpdated()"/>
        </div>
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_max_valoration') +': '+ question.rankingMax}}</label>
          <RangeSlider class="RangeSlider--Gradient" v-model="question.rankingMax" :min="minRanking" :max="maxRanking" :hasError="{ enabled: rankingError, type: 'ranking' }" showActions v-on:update-finished="dataIsUpdated()"/>
        </div>
      </div>
      <div class="QuestionSurvey--BasicGroup" v-if="isQuestionWithMinMax()">
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_min_selection')  +': '+ question.multiselectionMin}}</label>
          <RangeSlider class="RangeSlider--Gradient" v-model="question.multiselectionMin" :min="1" :max="questionMaxSelection" showActions v-on:update-finished="dataIsUpdated()"/>
        </div>
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_max_selection')  +': '+ question.multiselectionMax}}</label>
          <RangeSlider class="RangeSlider--Gradient" v-model="question.multiselectionMax" :min="questionMinSelection" :max="questionMaxSelection" :hasError="{ enabled: selectionError, type: 'multiselection' }" showActions v-on:update-finished="dataIsUpdated()"/>
        </div>
      </div>
      <div class="QuestionSurvey--BasicGroup" v-if="question.type == 'DIVIDE_POINTS'">
        <div class="QuestionSurvey--BasicGroupElement">
          <label>{{$t('activity_survey_divide_points_to_distribute')}}</label>
          <SuiteInput ref="PointsNum" required type="number" :minLength="1" :validation="/^\d+$/" class="Input--SurveyCreation" placeholder='Ej. "100"' v-model="question.pointsNum" v-on:data-update="dataIsUpdated()"/>
        </div>
      </div>
      <draggable class="QuestionSurvey--Values" v-if="isQuestionWithValues()" v-model="valuesComputed" item-key="id" handle=".icon-move-icon" v-bind="dragOptions" >
        <template #item="{element,index}">
          <div class="QuestionSurvey--Value">
            <span class="QuestionSurvey--ValueIcon" v-show="!isMultiSelection()"></span>
            <span class="QuestionSurvey--ValueIcon QuestionSurvey--ValueIconMulti" v-show="isMultiSelection()"></span>
            <div class="QuestionSurvey--ImageValue" v-if="isImageQuestion()">
              <div class="QuestionSurvey--ImageValueInput">
                <img src="../../assets/img/studyIcons/addImage.svg" v-if="element.image != undefined && element.image.length == 0">
                <div class="QuestionSurvey--FileViewer" v-if="element.image && element.image.length > 0">
                  <div :style="{'backgroundImage':'url('+element.image+')'}">
                  </div>
                </div>
                <p v-if="element.image != undefined && element.image.length == 0">Subir imagen</p>
                <p v-else class="uploaded">Imagen subida</p>
                <Dropzone
                  class="QuestionSurvey--Dropzone"
                  :ref="'imageValueUploader-' + index"
                  :uploadUrl="filePath"
                  :uploadHeaders="headers"
                  :uploadText="''"
                  :acceptedFiles="'image/jpg,image/jpeg,image/png'"
                  :maxFiles="1"
                  v-on:file-uploaded="imageValueUploadSuccess($event,index)" />
              </div>
            </div>
            <SuiteInput v-show="!isImageQuestion()" :minLength="1" :ref="'Value-' + index" required class="Input--SurveyCreation" :placeholder="$t('activity_survey_placeholder_answer')" v-model="element.value" v-on:data-update="dataIsUpdated()"/>
            <div class="QuestionSurvey--ValueActions">
              <span class="QuestionSurvey--AddTxt" v-show="element.addtxt">
                <i class="fas fa-align-left"></i>
              </span>
              <span class="icon-move-icon"></span>
              <span class="icon-delete-icon" v-on:click="$emit('delete-value', index); hasOptionValue();"></span>
              <span v-if=(isMultiSelection())
                class="icon-exclusive"
                :class="{'active': element.exclusive}"
                @click="toggleExclusive(index)"
                role="button"
                aria-pressed="element.exclusive"
                tabindex="0"
                @keydown.enter.prevent="toggleExclusive(index)"
              >
                <i class="fas fa-lock"></i>
              </span>
              <span 
                class="icon-anchor"
                v-if="question.rdmAnswers"
                :class="{'active': element.anchor}"
                @click="toggleAnchor(index)"
                role="button"
                aria-pressed="element.anchor"
                tabindex="0"
                @keydown.enter.prevent="toggleAnchor(index)"
              >
                <i class="fa-solid fa-thumbtack"></i>
              </span>
            </div>
          </div>
        </template>
      </draggable>
      <div class="QuestionSurvey--AddValue" v-if="isQuestionWithValues()">
        <p v-on:click="$emit('add-value'); hasOptionValue();"><img src="../../assets/img/studyIcons/add-black.svg">{{$t('activity_survey_new_answer')}}</p>
        <p v-if="!isMatrix()" v-on:click="$emit('add-txt'); hasOptionValue();"><img src="../../assets/img/studyIcons/add-black.svg">{{$t('activity_survey_new_option')}}</p>
        <br/>
        <div class="QuestionSurvey--ValueError">
          <p v-show="valuesError">{{$t('error_required_value_option')}}</p>
        </div>
      </div>
      <draggable class="QuestionSurvey--Values" v-if="isMatrixRadioButton()" v-model="question.radioOptionTexts" item-key="index" handle=".icon-move-icon" v-bind="dragOptions" >
        <template #item="{element,index}">
          <div class="QuestionSurvey--Value">
            <span class="QuestionSurvey--ValueIcon"></span>
            <SuiteInput :ref="'OptionValue-' + index" :minLength="1" required class="Input--SurveyCreation" :placeholder="$t('activity_survey_placeholder_answer')" v-model="question.radioOptionTexts[index]" v-on:data-update="dataIsUpdated()"/>
            <div class="QuestionSurvey--ValueActions">
              <span class="icon-move-icon"></span>
              <span class="icon-delete-icon" v-on:click="$emit('delete-value-option', index)"></span>
            </div>
          </div>
        </template>
      </draggable>
      <div class="QuestionSurvey--AddValue" v-if="isMatrixRadioButton()">
        <p v-on:click="$emit('add-option')"><img src="../../assets/img/studyIcons/add-black.svg">{{$t('activity_survey_new_element')}}</p>
      </div>
      <div class="QuestionSurvey--Footer">
        <p  v-on:click="collapseConfig = !collapseConfig; collapseRules = false">
          {{$t('new_activity_survey_configuration')}}
          <Collapsable class="QuestionSurvey--Collapsable" :collapsed="!collapseConfig"/>
        </p>
        <p  v-on:click="collapseRules = !collapseRules; collapseConfig = false" v-show="isTextLabelQuestion">
          {{$t('new_activity_survey_logic')}}
          <Collapsable class="QuestionSurvey--Collapsable" :collapsed="!collapseRules"/>
        </p>
      </div>
    </div>
  <transition name="list" v-if="!isCheckpoint">
      <div class="QuestionSurvey--Configuration" v-if="collapseConfig">
          <QuestionConfig :question="question" :filePath="filePath" :videoPath="videoPath" :headers="headers"/>
      </div>
    </transition>
    <transition name="list" v-if="!isCheckpoint">
      <div class="QuestionSurvey--Configuration" v-if="collapseRules">
          <NavigationRules :rules="questionRules" :questionList="questionList" :question="question" v-on:add-rule="$emit('add-rule')" v-on:change-rule="$emit('change-rule')"/>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ApiService from '../../services/api.service.ts'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import QuestionIMG from '../Question/QuestionIMG.vue'
import Dropzone from '../Dropzone';
import NavigationRules from './NavigationRules.vue';
import SuiteInput from '../SuiteInput/SuiteInput.vue'
import RangeSlider from '../RangeSlider/RangeSlider.vue'
import Collapsable from '../Collapsable/Collapsable.vue'
import QuestionConfig from './QuestionConfig.vue'
import { isQuestionWithValues, isQuestionText } from '../../utils/survey-creation.ts'
export default defineComponent({
  name : 'QuestionSurvey',
  components:{
    QuestionIMG,
    SuiteInput,
    RangeSlider,
    draggable,
    Collapsable,
    QuestionConfig,
    Dropzone,
    NavigationRules
  },
  emits:['add-value','add-txt','add-option','duplicate-question','delete-question', 'delete-value-option', 'delete-value', 'add-rule', 'data-updated', 'change-rule', 'delete-checkpoint', 'duplicate-checkpoint'],
  props:{
    question: {
      type: Object,
      default:{}
    },
    questionList: {
      type: Array,
      default: new Array()
    },
    rules:{
      type: Array,
      defauly: new Array()
    },
    isPollActivity:{
      type: Boolean,
      default: false,
    },
    taskNum:{
      type: [String, Number],
      default: 1
    },
    isCheckpoint:{
      type: Boolean,
      default: false
    },
    checkpoint:{
      type: Object,
      default: () => {}
    },
    checkpointOrder:{
      type: Number,
      default: 1
    },
    showDescription:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
    valuesComputed: {
        get(){
            return this.isImageQuestion() ? this.question.imageValues : this.question.values
        },
        set(newValue){
            this.isImageQuestion() ? this.question.imageValues = newValue : this.question.values = newValue
        }
    },
    questionMaxSelection(){
      return this.isImageQuestion() ? this.question.imageValues.length : this.question.values.length
    },
    questionMinSelection(){
      if (this.isImageQuestion()) {
        return this.question.imageValues.length == 0? 0: 1
      }else{
        return this.question.values.length == 0? 0: 1
      }
    },
    questionRules(){
      if(this.rules == undefined) return []
      if(this.rules.length == 0) return []
      let rules = []
      let rule = undefined
      for(let r of this.rules){
        const currentRule = r
        if(currentRule.conditions[0].qId == this.question.id && currentRule.conditions[0].taskNum == this.taskNum) rule = currentRule
        for(let c of currentRule.conditions){
          if(c.qId != this.question.id || currentRule.conditions[0].taskNum != this.taskNum){
            rule = undefined
            break;
          }
        }
        if(rule != undefined) rules.push(rule)
      }
      return rules
    },
    maxRanking(){
      if(this.question.type == 'RATING_FACES') return 5
      else return 10
    },
    minRanking(){
      if(this.question.type == 'RATING_FACES') return 1
      else return 0
    },
    isTextLabelQuestion(){
      return this.question.type != 'LABEL'
    }
  },
  data(){
    return{
      filePath: process.env.VUE_APP_FILE_UPLOAD_PATH,
      videoPath: process.env.VUE_APP_VIDEO_UPLOAD_PATH,
      headers: ApiService.getConfig().headers,
      collapseConfig: false,
      collapseRules: false,
      error: false,
      valuesError: false,
      rankingError: false,
      selectionError: false,
      titleWithStyles: false,
      switchState: false,
      exclusiveError: false, 
      currentErrorIndex: null, 
    }
  },
  methods:{
    toggleExclusive(index) {
      console.log(`toggleExclusive called with index: ${index}`);
      if (!this.isMultiSelection()) {
        console.log("La pregunta no es de tipo MULTISELECTION. Salida de la función.");
        return;
      }

      const selectedOption = this.valuesComputed[index];
      console.log("Opción seleccionada antes del cambio:", selectedOption);

      if (selectedOption.exclusive) {
        console.log("La opción ya es exclusiva. Desmarcando exclusividad.");
        selectedOption.exclusive = false;
      } else {
        console.log("Marcar como exclusiva y desmarcar las demás opciones.");
        this.valuesComputed.forEach((option, idx) => {
          option.exclusive = idx === index;
          console.log(`Opción ${idx}: exclusive = ${option.exclusive}`);
        });
      }

      console.log("Estado de 'valuesComputed' después del cambio:", this.valuesComputed);
      this.dataIsUpdated();
    },
    toggleAnchor(index) {
      console.log(`toggleAnchor called with index: ${index}`);
      if (!this.isrdmAnswers()) {
        console.log("La pregunta no tiene 'rdmAnswers' habilitado. Salida de la función.");
        return;
      }

      const selectedOption = this.valuesComputed[index];
      console.log("Opción seleccionada antes del cambio:", selectedOption);

      if (selectedOption.anchor) {
        console.log("La opción ya es ancla. Desmarcando ancla.");
        selectedOption.anchor = false;
      } else {
        console.log("Marcar como ancla.");
        selectedOption.anchor = true;
        // Opcional: si solo se permite una ancla, desmarcar las demás opciones
        // this.valuesComputed.forEach((option, idx) => {
        //   if(idx !== index) option.anchor = false;
        // });
      }

      console.log("Estado de 'valuesComputed' después del cambio:", this.valuesComputed);
      this.dataIsUpdated();
    },

    isQuestionWithValues(){
      return isQuestionWithValues(this.question.type)
    },
    isQuestionText(){
      return isQuestionText(this.question.type)
    },
    isQuestionWithMinMax(){
      return this.question.type == 'MULTISELECTION' || this.question.type == 'MULTIPLEIMAGE_CHOICE'
    },
    isRankingQuestion(){
      return this.question.type == 'RANKING' || this.question.type == 'RATING_FACES'
    },
    isMultiTextQuestion(){
      return this.question.type == 'MULTITEXT' || this.question.type == 'MULTINUMBER'
    },
    isNumberQuestion(){
      return this.question.type == 'MULTINUMBER' || this.question.type == 'NUMBER' || this.question.type == 'DECIMAL_NUMBER'
    },
    isRankingEnabled(){
      return this.isRankingQuestion() || (this.question.type == 'MATRIX' && !this.question.matrixRadioButton)
    },
    isMatrixRadioButton(){
      return this.question.type == 'MATRIX' && this.question.matrixRadioButton
    },
    isMatrixNoRadioButton(){
      return this.question.type == 'MATRIX' && !this.question.matrixRadioButton
    },
    isMatrix(){
      return this.question.type == 'MATRIX'
    },
    isMultiSelection(){
      return this.question.type == 'MULTISELECTION' || this.question.type == 'MULTIPLEIMAGE_CHOICE'
    },
    isImageQuestion(){
      return this.question.type == 'IMAGE_CHOICE' || this.question.type == 'MULTIPLEIMAGE_CHOICE'
    },
    isrdmAnswers() {
      return this.question.rdmAnswers === true;
    },
    hasOptionValue(){
      this.valuesError = false;
      if (this.isQuestionWithValues() && !this.isImageQuestion() && this.question.values.length == 0) this.valuesError = true;
    },
    hasRankingError(){
      this.rankingError = false;
      if (this.isRankingQuestion() && this.question.rankingMin >= this.question.rankingMax) this.rankingError = true;
    },
    hasSelectionError(){
      this.selectionError = false;
      if (this.isQuestionWithMinMax() && this.question.multiselectionMin > this.question.multiselectionMax) this.selectionError = true;
    },
    hasErrors() {
    this.error = false;
    const title = this.$refs.Title;
    let errors = [];

    if (title) {
        errors.push(title.hasErrors());
    }

    if (this.isImageQuestion() && (!this.question.imageValues || this.question.imageValues.length === 0)) {
        errors.push(true);
    }

    if (this.isQuestionWithValues() && !this.isImageQuestion() && (!this.question.values || this.question.values.length === 0)) {
        errors.push(true);
    }

    if (this.isQuestionWithMinMax() && this.question.multiselectionMin > this.question.multiselectionMax) {
        errors.push(true);
    }

    if (this.isRankingQuestion() && this.question.rankingMin >= this.question.rankingMax) {
        errors.push(true);
    }

    this.hasOptionValue();
    this.hasRankingError();
    this.hasSelectionError();

    if (this.question.values) {
        for (let index in this.question.values) {
            const value = this.$refs['Value-' + index];
            if (value) {
                errors.push(value.hasErrors());
            }
        }
    }

    if (this.isMatrixRadioButton() && this.question.radioOptionTexts) {
        for (let index in this.question.radioOptionTexts) {
            const value = this.$refs['OptionValue-' + index];
            if (value) {
                errors.push(value.hasErrors());
            }
        }
    }

    if (this.question.type === 'DIVIDE_POINTS') {
        const pointsNum = this.$refs.PointsNum;
        if (pointsNum) {
            errors.push(pointsNum.hasErrors());
        }
    }

    if (this.isNumberQuestion()) {
        const digits = this.$refs.Digits;
        const decimals = this.$refs.Decimals;
        if (digits) {
            errors.push(digits.hasErrors());
        }
        if (decimals) {
            errors.push(decimals.hasErrors());
        }
    }
      if (this.isMultiSelection()) {
        const exclusiveOptions = this.valuesComputed.filter(option => option.exclusive);
        if (exclusiveOptions.length > 1) {
          this.exclusiveError = true;
          this.currentErrorIndex = this.valuesComputed.findIndex(option => option.exclusive);
          errors.push(true);
        } else {
          this.exclusiveError = false;
          this.currentErrorIndex = null;
        }
      }

      this.error = errors.includes(true) || this.exclusiveError;
      console.log(this.error)
    return this.error;
},

    imageValueUploadSuccess(ev,index){
      this.question.imageValues[index].image = ev.data.path
      const imageUploader = this.$refs['imageValueUploader-' + index]
      imageUploader.reset()
      this.dataIsUpdated()
    },
    dataIsUpdated(){
      this.hasRankingError();
      this.hasSelectionError();
      this.$emit('data-updated')
    },
    switchDuration(){
      this.switchState = !this.switchState
      if (this.switchState) {
        this.titleWithStyles = true
      } else {
        this.titleWithStyles = false
      }
    },
    hasHTMLcontent() {
      var regex = /<([A-Z][A-Z0-9]*)([^>]*?)>(.*?)<\/\1>/i;
      return regex.test(this.question.text)
    },
    clearHTMLtags(){
      this.switchState = false
      this.titleWithStyles = false
      let parser = new DOMParser()
      let docWithoutHTML = parser.parseFromString(this.question.text, 'text/html')
      this.question.text = docWithoutHTML.body.textContent
    }
  },
  mounted(){
    if(this.showDescription){
      const textarea = document.getElementById('auto-resize-' + this.question.id);
      const autoResize = () => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      };
      autoResize();
      textarea.addEventListener('input', autoResize);
    }
    // Inicializar 'exclusive' y 'anchor' si no están definidos
    if (this.isMultiSelection()) {
      this.valuesComputed.forEach(option => {
        if (typeof option.exclusive === 'undefined') {
          option.exclusive = false;
        }
      });
    }
    console.log(this.questionList)
  }
})
</script>
<style scoped lang="scss">
/* Estilos Globales y Basicos */
.QuestionSurvey {
  position: relative;
  background: white;

  textarea {
    width: 100%;
    resize: none;
    overflow: hidden;
    min-height: 50px;
    max-height: 300px;
    color: #666666;
    padding: 0.6rem 1rem;
    border: 1px solid #e4e4e4;
  }

  &--Error {
    .QuestionSurvey--Data {
      box-shadow: 0 0 6px red;
    }
  }

  &--Data {
    position: relative;
    border: 1px solid #C8C8C8;
    padding: 25px 32px 48px 32px;
  }

  &--IndexWrapper {
    position: absolute;
    display: flex;
    gap: 8px;
    top: -25px;
    left: 30px;
  }

  &--Index {
    color: white;
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 29px;
    width: 50px;
    height: 50px;
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--Logo {
    border: 1px solid #EAEAEA;
    background: white;

    img {
      width: 27px;
    }
  }

  &--Actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 10px;

    span {
      font-size: 20px;
      cursor: pointer;
      color: #A6A6A6;

      &:hover {
        color: #96C800;
      }
    }
  }

  &--Desc {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 15px;

    label {
      font-family: 'Bariol Bold';
      font-weight: 700;
      font-size: 18px;
    }
  }

  &--Title {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    label {
      font-family: 'Bariol Bold';
      font-weight: 700;
      font-size: 18px;
    }

    .StylesInfo {
      font-size: 14px;

      span {
        margin-left: 3px;
        color: #e56e2e;

        .Clear {
          color: #2349c5;
        }
      }

      i {
        color: #e56e2e;
      }
    }
  }

  &--BasicGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    margin-top: 16px;

    label {
      font-family: 'Bariol Bold';
      font-size: 18px;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  &--BasicGroupElement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
  }

  &--Values {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &--Value {
    display: flex;
    align-items: center;
    gap: 24px;
    position: relative;
  }

  &--ValueIcon {
    background: #FFFFFF;
    padding: 10px;
    width: 26px;
    height: 26px;
    border: 3.5px solid #C8C8C8;
    border-radius: 50%;
  }

  &--ValueIconMulti {
    border-radius: 0px;
  }

  &--ValueActions {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    gap: 16px;
    color: black;
    font-size: 18px;
    margin-right: 20px;

    span {
      cursor: pointer;

      &:hover {
        color: #96C800;
      }
    }

    i {
      font-size: 16px;
    }

    .icon-delete-icon {
      color: red;
    }
  }

  &--AddTxt {
    cursor: pointer;
    position: relative;

    &:hover {
      transition: 0.5s;
    }

    &:before,
    &:after {
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -10px);
      opacity: 0;
      transition: 0.2s;
    }

    &:before {
      content: '';
      top: calc(100% - 3px);
      border: solid 5px transparent;
      border-bottom-color: #FF1564;
    }

    &:after {
      content: "Opción texto a rellenar";
      top: calc(100% + 6px);
      padding: 0.61em 0.93em;
      color: white;
      border-radius: 3px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      font-weight: 500;
      font-size: 13px;
      font-family: Lato;
      z-index: 1;
    }

    &:hover:before,
    &:hover:after {
      opacity: 1;
      transform: translate(-50%);
    }
  }

  &--AddValue {
    display: flex;
    margin-left: 40px;
    margin-top: 12px;

    img {
      width: 9px;
    }

    p {
      font-family: 'Lato';
      font-weight: 800;
      font-size: 10px;
      text-align: center;
      color: #96C800;
      text-transform: uppercase;
      display: flex;
      align-items: baseline;
      gap: 6px;
      padding: 9px 16px;
      cursor: pointer;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  &--ValueError {
    position: absolute;
    bottom: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    left: 6%;

    p {
      color: red;
      font-size: 12px;
      font-weight: 500;
      text-transform: none;
    }
  }

  &--Footer {
    display: flex;
    gap: 8px;
    position: absolute;
    bottom: 0;
    right: 32px;

    p {
      font-family: 'Lato';
      font-weight: 900;
      font-size: 10px;
      color: #626262;
      text-transform: uppercase;
      background: #F8F8F8;
      padding: 11px 16px 8px 16px;
      display: flex;
      align-items: center;
      gap: 7px;
      cursor: pointer;
    }
  }

  &--Collapsable {
    font-size: 9px;
    width: fit-content;
    height: fit-content;
    background: #F8F8F8;
  }

  &--Configuration {
    border: 1px solid #C8C8C8;
    border-top: 0px;
    width: 100%;
    padding: 40px 30px;
  }

  &--ImageValue {
    border: 1px solid #e4e4e4;
    padding: 0.5rem 1rem;
    font-size: 15px;
    outline: none;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    p {
      opacity: 0.65;

      &.uploaded {
        color: #96C800;
      }
    }

    img {
      width: 20px;
    }
  }

  &--ImageValueInput {
    cursor: pointer;
    display: flex;
    gap: 5px;
    position: relative;
  }

  &--Dropzone {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      background: transparent;
    }
  }

  &--FileViewer {
    display: flex;
    gap: 15px;

    div {
      position: relative;
      border: 1px solid gray;
      border-radius: 3px;
      height: -webkit-fill-available;
      width: 20px;
      background-position: center;
      background-size: contain;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  /* Estilos para Exclusividad */
  .icon-exclusive,.icon-anchor {
    font-size: 18px;
    color: #A6A6A6;
    cursor: pointer;
    transition: color 0.3s;

    &.active {
      color: #FF1564; // Ajusta según tu diseño
    }

    &:hover {
      color: #FF1564;
      /* Copy code to element you want to animate */
        animation: customAni 2s cubic-bezier(0.61, 1, 0.88, 1) 0s 1 normal none;
    }
  }

  .QuestionSurvey--ExclusiveError {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }

  /* Estilos para el Switcher de Título */
  .TitleWithSwitcher {
    display: flex;
    flex-direction: row;
  }

  .StylesSwitcher {
    margin-left: 20px;
    margin-top: -5px;
    width: 120px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 50% 50%;
    position: relative;
    font-family: 'Lato';
    font-weight: 900;
    font-size: 12px;

    p {
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      z-index: 1;
      transition: 0.5s;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        color: white;
      }
    }

    &.switched:before {
      left: 50%;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      border-radius: 3px;
      height: calc(100% - 8px);
      width: calc(50% - 8px);
      margin: 4px;
      transition: 0.5s;
    }
  }

  /* Transiciones */
  .list-enter,
  .list-leave-to {
    visibility: hidden;
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    // opacity: 0;
  }

  .list-enter-active,
  .list-leave-active {
    transition: 0.1s;
  }
}


/* Copy this code after the above code */
/* Copy this code after the above code */
@keyframes customAni {

  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  10% {
    transform: rotate(8deg);
  }

  20%,
  40%,
  60% {
    transform: rotate(-10deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(-8deg);
  }

  90% {
    transform: rotate(8deg);
  }
}
</style>