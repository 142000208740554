<template>
  <div class="ModalFullScreen">
    <div class="ModalFullScreen--Modal">
      <div class="ModalFullScreen--Close" v-on:click="$emit('close')">
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.5 24.9516L17.5484 17L25.5 9.04839C25.6514 8.89697 25.6514 8.65143 25.5 8.5C25.3486 8.34858 25.103 8.34858 24.9516 8.50001L17 16.4516L9.04838 8.50001C8.89696 8.34858 8.65142 8.34858 8.5 8.5C8.34857 8.65143 8.34857 8.89697 8.49999 9.04839L16.4516 17L8.5 24.9516C8.34857 25.1031 8.34857 25.3486 8.5 25.5C8.65142 25.6514 8.89696 25.6514 9.04838 25.5L17 17.5484L24.9516 25.5C25.103 25.6514 25.3486 25.6514 25.5 25.5C25.6514 25.3486 25.6514 25.1031 25.5 24.9516Z" fill="#96C800" stroke="#96C800"/>
        </svg>
      </div>
      <slot/>
    </div>
  </div>
</template>
<style scoped lang="scss">
.ModalFullScreen{
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left: 0;
  z-index: 9999;
  background: rgba(0,0,0,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  &--Modal{
    width: 85%;
    border: 3px solid rgba(255, 255, 255, 0.3);
    position: relative;
    background: white;
    padding: 20px;
  }
  &--Close{
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
    position: absolute;
    right: 20px;
    top: -15px;
    cursor: pointer;
    z-index:1;
  }
}
</style>
